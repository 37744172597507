<template>
    <v-container
        tag="section"
    >
        <v-row
            justify="center"
            v-if="packageAll.paytime >= dateNow"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                          Результат по здоровью (Star Health)
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                      <p class="mb-5">Марафон ЗОЖ и коррекции веса StarHealth - практический курс по здоровью и бадам.</p>
                      <p class="mb-5">
                        Цель - Получить результат от продуктов компании, скорректировать вес на 5-10кг , начать комплексно решать вопросы по здоровью. Дать клиентам уверенность в продукте и расширить их потребительскую корзину, увеличив тем самым свой оборот.
                      </p>
                      <p class="mb-5">
                        <a href="https://teletype.in/@starhealth/a0ZAciCtDC6" target="_blank">Инструкция ЗОЖ для команды StarTrack</a>
                      </p>
                      <p class="mb-5">
                        <a href="http://starsales.tilda.ws/starhealth" target="_blank">Ссылка на инструмент по расширению потребительской корзины, Марафон ЗОЖ</a>
                      </p>
                      <p class="mb-5">
                        <a href="https://t.me/+z1eG838UbDI0ZDIy" target="_blank">Предварительный чат марафона</a>
                      </p>
                      <p class="mb-5">
                        <a href="https://vk.com/starhealth" target="_blank">Группа марафона</a>
                      </p>
                      <p class="mb-5">
                        <a href="https://teletype.in/@starhealth/5lK7ADP2E-3" target="_blank">Инструкция как увеличить оборот за счет StarHealth</a>
                      </p>
                      <p class="mb-5">
                        <a href="https://teletype.in/@starhealth/Q9gBeVC3wxn" target="_blank">Скрипты приглашения на марафон StarHealth</a>
                      </p>
                      <p class="mb-5">
                        <a href="https://teletype.in/@starhealth/AmEaTG6K2Kc" target="_blank">Посты приглашения на марафон StarHealth</a>
                      </p>
                      <p class="mb-5">
                        <a href="https://teletype.in/@starhealth/badyStarHealth1" target="_blank">Рекомендуемые наборы БАДов для марафона</a>
                      </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            v-if="!packageAll.paytime || packageAll.paytime < dateNow"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
            >
                <base-material-card
                    color="red"
                    class="px-md-16 px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Ошибка доступа
                        </div>

                        <div class="subtitle-1 font-weight-light">

                        </div>
                    </template>

                    <v-card-text
                        class="mt-10"
                    >
                        <p>
                            <v-icon
                                color="red"
                                class="mr-2"
                            >
                                mdi-alert-circle-outline
                            </v-icon>
                            У вас нет доступа к просмотру данной страницы.</p>
                        <p>
                            <v-icon
                                class="mr-2"
                                color="blue"
                            >
                                mdi-arrow-right-bold-outline
                            </v-icon>
                            Для получения доступа к инструментам обратитесь к куратору:
                            <router-link
                                color="success"
                                :to="{name: 'PayInstruments'}"
                            >
                                Как получить доступ к инструментам
                            </router-link>
                        </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "StarHealthLearn",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
          packageAll: 'user/getProfileAll'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAll: 'user/loadProfileAll',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAll()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.p-title
  text-transform: uppercase
  font-weight: bold

.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
